.energyCard {
    padding: 1rem; // Adds padding inside the card for spacing between content and card border
    background-color: #fff; // Sets the background color of the card to white
    border-radius: 15px; // Rounds the corners of the card with a 15px radius
    width: 350px; // Sets a fixed width for the card to ensure consistent layout

    .cardHead {
        display: flex; // Uses Flexbox layout for positioning child elements
        align-items: center; // Vertically aligns items in the center of the container
        justify-content: space-between; // Distributes the child elements with space in between

        .machineName {
            font-weight: 600; // Makes the machine name text bold
            font-size: 16px; // Sets the font size of the machine name to 16px
            color: #202224; // Sets the text color to a dark gray
        }

        .machineType {
            font-weight: 400; // Sets the font weight to normal
            font-size: 11px; // Reduces the font size of the machine type
            color: #202224; // Sets the text color to dark gray
            margin-top: -5px; // Adds a negative margin to adjust vertical spacing between elements
        }

        .machineStatus {
            font-size: 12px; // Font size of the status text
            font-weight: 600; // Makes the status text bold
            padding: 0.5rem 1rem; // Adds padding around the status text for better readability
            border-radius: 5px; // Rounds the corners of the status badge for a soft look

            &.working {
                background-color: rgba(#00B69B, 0.2); // Sets a light green background color for the "working" status
                color: #00B69B; // Green text color for the "working" status
            }

            &.offline {
                background-color: rgba(#EF3826, 0.2); // Sets a light red background color for the "offline" status
                color: #EF3826; // Red text color for the "offline" status
            }

            &.idle {
                background-color: rgba(#FD9A56, 0.2); // Sets a light orange background color for the "idle" status
                color: #FD9A56; // Orange text color for the "idle" status
            }

            &.maintenance {
                background-color: rgba(#7e7e7e, 0.2); // Sets a light gray background color for the "maintenance" status
                color: #7e7e7e; // Gray text color for the "maintenance" status
            }
        }
    }

    cursor: pointer; // Changes the cursor to a pointer when hovered over the card (indicating it's clickable)

    &:hover {
        scale: 1.05; // Increases the size of the card slightly when hovered (providing a subtle zoom effect)
        transition: all 0.5s ease; // Applies a smooth transition to all properties over 0.5 seconds when hovered
    }

    .energyCardBody {
        padding: 1rem 0.5rem; // Adds padding inside the body of the card for spacing

        .rowHeading {
            font-size: 14px; // Sets the font size for row headings
            font-weight: 400; // Sets normal font weight for row headings
            color: #808285; // Sets a lighter gray color for row headings
        }

        .statRow {
            display: flex; // Uses Flexbox for positioning elements in a row
            align-items: center; // Vertically aligns elements in the row
            justify-content: space-between; // Distributes items in the row with space between them

            .boxStyle {
                padding: 0.5rem; // Adds padding inside each box
                background: linear-gradient(135deg, rgba(74,217,145,1) 0%, rgba(0,182,155,1) 100%); // Adds a gradient background with two shades of green
                border-radius: 5px; // Rounds the corners of each box

                .heading {
                    color: #606264; // Sets a medium gray color for the heading text
                    font-size: 12px; // Reduces the font size of the heading text
                }

                .value {
                    font-size: 15px; // Increases the font size of the value text
                    font-weight: 500; // Sets the font weight to medium for value text
                }
            }
        }
    }
}
