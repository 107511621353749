.financialReport {
    // Header section styling
    .financialReportHead {
        display: flex; // Use flexbox for layout
        align-items: center; // Center items vertically
        justify-content: space-between; // Space items evenly

        // Intro section within the header
        .financialReportIntro {
            display: flex; // Use flexbox for layout
            align-items: center; // Center items vertically
            justify-content: space-between; // Space items evenly
            gap: 1rem; // Space between elements

            .financialHeading {
                font-size: 24px; // Font size for the heading
                font-weight: 500; // Font weight for the heading
            }
        }

        // Filters section within the header
        .financialReportFilters {
            display: flex; // Flexbox layout for the filters section
            gap: 0.5rem; // Space between filter elements

            // React date picker wrapper styling
            .react-datepicker-wrapper {
                height: 100%; // Full height for the wrapper
                .react-datepicker__input-container {
                    height: 100%; // Full height for the input container
                    input {
                        height: 100%; // Full height for the input
                        width: 100%; // Full width for the input
                        border: none; // Remove border from the input
                    }
                }
            }

            // Button styling
            .button {
                padding: 0.5rem 1rem; // Padding inside the button
                color: #fff; // White text color
                background-color: #4880FF; // Background color
                border-radius: 10px; // Rounded corners for the button
            }
        }
    }

    // Stats section styling
    .financialReportStats {
        margin-top: 15px; // Margin top for spacing
        display: flex; // Flexbox layout for the stats container
        gap: 1rem; // Space between stats cards
        padding: 1rem; // Padding around the container
        justify-content: space-between; // Distribute space between stats items
    }

    // Chart filter section styling
    .financialReportChartFilter {
        display: grid; // Grid layout for the chart filter section
        grid-template-columns: 3fr 1fr; // Three-forth for the filter and one-fourth for other elements
        gap: 1.5rem; // Space between items
        margin-top: 30px; // Margin for spacing
        align-items: center; // Center elements vertically
        justify-content: space-between; // Space items evenly

        .header {
            display: flex; // Flexbox layout for the header
            align-items: center; // Align items vertically
            justify-content: space-between; // Space out the header elements

            .Filters {
                display: flex; // Flexbox layout for the filters
                align-items: center; // Align items vertically
                justify-content: center; // Center the items
                background-color: #FAFBFD; // Background color for the filter section

                // Button for filtering chart types
                .chartFilterButton {
                    height: 40px; // Button height
                    border: 1px solid #979797; // Border color
                    border-radius: 0; // No rounded corners
                    margin-bottom: 10px; // Bottom margin for spacing
                    color: #000; // Text color

                    // Active state for the filter button
                    &.active {
                        background-color: #000; // Background color when active
                        color: #fff; // Text color when active
                    }

                    // Styling for the left button
                    &.left {
                        border-top-left-radius: 10px; // Rounded top left corner
                        border-bottom-left-radius: 10px; // Rounded bottom left corner
                    }

                    // Styling for the right button
                    &.right {
                        border-top-right-radius: 10px; // Rounded top right corner
                        border-bottom-right-radius: 10px; // Rounded bottom right corner
                    }

                    // Hover effect for the filter button
                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Add shadow on hover
                    }
                }
            }
        }
    }

    // Financial details section styling
    .financialDetails {
        display: grid; // Grid layout for the details section
        grid-template-columns: 3fr 1fr; // Three-forth for details and one-fourth for other elements
        gap: 1.5rem; // Space between items
        align-items: flex-start; // Align items at the start
        justify-content: flex-start; // Align content to the start

        // Additional details section within the financial details
        .additionalDetails {
            display: flex; // Flexbox layout for additional details
            flex-direction: column; // Stack items vertically
            gap: 1rem; // Space between items
        }
    }
}
