.productionDetail {
    .productionDetailHead {
        display: flex; // Use flexbox for layout
        align-items: center; // Center items vertically
        justify-content: space-between; // Space items evenly across the width

        .productionDetailIntro {
            display: flex; // Flexbox layout for child elements
            align-items: center; // Vertically align items in the center
            justify-content: space-between; // Space out the child elements evenly
            gap: 1rem; // Add space between child elements

            .machineName {
                font-size: 24px; // Larger font size for the machine name
                font-weight: 500; // Medium font weight for the machine name
            }

            .machineStatus {
                font-size: 12px; // Smaller font size for machine status
                font-weight: 600; // Bold font for the machine status
                padding: 0.5rem 1rem; // Padding around the status badge
                border-radius: 5px; // Rounded corners for status badge

                // Conditional styling based on the machine status
                &.working {
                    background-color: rgba($color: #00B69B, $alpha: 0.2); // Background color for working status
                    color: #00B69B; // Text color for working status
                }

                &.offline {
                    background-color: rgba($color: #EF3826, $alpha: 0.2); // Background color for offline status
                    color: #EF3826; // Text color for offline status
                }

                &.idle {
                    background-color: rgba($color: #FD9A56, $alpha: 0.2); // Background color for idle status
                    color: #FD9A56; // Text color for idle status
                }

                &.maintenance {
                    background-color: rgba($color: #7e7e7e, $alpha: 0.2); // Background color for maintenance status
                    color: #7e7e7e; // Text color for maintenance status
                }
            }
        }

        .productionDetailFilters {
            display: flex; // Flexbox layout for the filter buttons
            gap: 0.5rem; // Space between filter buttons

            .react-datepicker-wrapper {
                height: 100%; // Ensure the datepicker takes full height
                .react-datepicker__input-container {
                    height: 100%; // Input container takes full height
                    input {
                        height: 100%; // Input field takes full height
                        width: 100%; // Input field takes full width
                        border: none; // Remove border for a cleaner appearance
                    }
                }
            }

            .button {
                padding: 0.5rem 1rem; // Padding around the button
                color: #fff; // White text color
                background-color: #4880FF; // Button background color (blue)
                border-radius: 10px; // Rounded corners for the button
            }
        }
    }

    .productionDetailStats {
        margin-top: 15px; // Add space between stats and previous section
        display: flex; // Use flexbox layout for stats container
        gap: 1rem; // Space between individual stats cards
        padding: 1rem; // Add padding around the container
        justify-content: space-between; // Distribute space evenly between stats
    }

    .machineDetailChartFilter {
        display: grid; // Use grid layout for chart filter section
        grid-template-columns: 3fr 1fr; // Three parts for chart type selection and chart area
        gap: 1.5rem; // Space between grid items
        margin-top: 30px; // Add space above the filter section
        align-items: center; // Vertically center the grid items
        justify-content: space-between; // Space out the content across the grid

        .header {
            display: flex; // Flexbox layout for the header section
            align-items: center; // Vertically align items in the center
            justify-content: space-between; // Space out the header items evenly

            .Filters {
                display: flex; // Flexbox layout for filter buttons
                align-items: center; // Center the filter buttons vertically
                justify-content: center; // Center the filter buttons horizontally
                background-color: #FAFBFD; // Light background color for the filter section

                .chartFilterButton {
                    height: 40px; // Set fixed height for chart filter buttons
                    border: 1px solid #979797; // Light border for buttons
                    border-radius: 0; // Remove border radius for a uniform appearance
                    color: #000; // Default text color for buttons

                    // Style for the active chart filter button
                    &.active {
                        background-color: #000; // Dark background for active button
                        color: #fff; // White text for active button
                    }

                    // Styling for left-most button
                    &.left {
                        border-top-left-radius: 10px; // Rounded top-left corner
                        border-bottom-left-radius: 10px; // Rounded bottom-left corner
                    }

                    // Styling for right-most button
                    &.right {
                        border-top-right-radius: 10px; // Rounded top-right corner
                        border-bottom-right-radius: 10px; // Rounded bottom-right corner
                    }

                    // Hover effect for the buttons
                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow on hover
                    }
                }
            }
        }
    }

    .machineDetailDetails {
        display: grid; // Grid layout for machine details section
        grid-template-columns: 3fr 1fr; // Three parts for machine data and additional details
        gap: 1.5rem; // Space between the grid items
        align-items: flex-start; // Vertically align items in the grid
        justify-content: flex-start; // Left-align the items in the grid

        .additionalDetails {
            display: flex; // Use flexbox for additional details section
            flex-direction: column; // Stack the details vertically
            gap: 1rem; // Space between individual additional details
        }
    }
}
