.machines {
    display: flex; // Flexbox layout for the main container
    flex-direction: column; // Stack children vertically

    .machineStatsConatiner {
        display: flex; // Flexbox layout for the stats container
        gap: 1rem; // Space between cards
        padding: 1rem; // Add some padding around the container
        justify-content: space-between; // Distribute space between items
    }

    .machinesHeader {
        margin-bottom: 15px; // Space below the header
        display: flex; // Flexbox layout for the header
        align-items: center; // Center items vertically
        justify-content: space-between; // Space items evenly

        .headerHeading {
            font-size: 16px; // Font size for the header text
            margin-bottom: -30px;
        }

        .headerFilters {
            display: flex; // Flexbox layout for the filters
            align-items: center; // Center items vertically
            gap: 1rem; // Space between filters
            min-width: 400px; // Minimum width for the filters container
            flex-wrap: nowrap; // Prevent wrapping of filter items
        }
    }

    .machinesList {
        display: flex; // Flexbox layout for the list of machines
        align-items: center; // Center items vertically
        gap: 1.5rem; // Space between machine cards
        flex-wrap: wrap; // Allow wrapping of machine cards
    }
    
    .MuiChip-root {
        margin: 0; // Remove margin to prevent wrapping
    }
}

// .headerFilters {
//     // padding: 20px;
//     // background-color: #f9f9f9;
//     // border-radius: 8px;
//     // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
// }

// .headerFilters .MuiFormControl-root {
//     // margin-bottom: 16px;
// }

.headerFilters .MuiInputLabel-root {
    color: #2B3034;
}

.headerFilters .MuiSelect-root {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.headerFilters .MuiSelect-root:hover {
    border-color: #888;
}

.headerFilters .MuiSelect-root.Mui-focused {
    border-color: #3f51b5;
    box-shadow: 0 0 5px rgba(63, 81, 181, 0.5);
}

.headerFilters .MuiChip-root {
    background-color: #e0e0e0;
    color: #333;
    border-radius: 16px;
}

.headerFilters .MuiButton-root {
    background-color: #3f51b5;
    color: white;
    transition: background-color 0.3s;
}

.headerFilters .MuiButton-root:hover {
    background-color: #303f9f;
}

@media (max-width: 600px) {
    .headerFilters {
        padding: 10px;
    }

    .headerFilters .MuiFormControl-root {
        margin-bottom: 12px;
    }
}